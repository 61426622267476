import React, { createContext, useContext, useState } from "react"

const isBrowser = typeof window !== `undefined`

const defaultState = {
  token: "",
  cartCounter: 0,
  setToken: () => {},
  setCartCounter: () => {},
}
export const AuthContext = createContext(defaultState)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [token, setToken] = useState(
    isBrowser && window.localStorage.User
      ? JSON.parse(window.localStorage.User).accessToken
      : ""
  )
  const [cartCounter, setCartCounter] = useState(0)

  return (
    <AuthContext.Provider
      value={{ token, setToken, cartCounter, setCartCounter }}
    >
      {children}
    </AuthContext.Provider>
  )
}
