/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import "./src/components/layout.css"
import React from "react"
import { AuthProvider } from "./src/hooks/useAuth"

export const wrapRootElement = ({ element }) => (
  <AuthProvider>{element}</AuthProvider>
)
